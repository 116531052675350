import { gql, useQuery } from "@apollo/client";
import { DeprecatedLink, useToast } from "@resource/atlas";
import { ErrorCodes } from "@resource/common";
import LoginForm from "components/LoginForm/LoginForm";
import { NotificationBanner } from "components/NotificationBanner";
import { CheckLoggedInLogin } from "generated/schemaTypes";
import guideLogoDark from "icons/guide-logo-horizontal--color-dark.svg";
import Head from "next/head";
import Image from "next/image";
import NextLink from "next/link";
import { useRouter } from "next/router";
import heroIllustration from "public/images/hero-illustration.png";
import { useEffect } from "react";
import {
  LOGIN_COMMUNICATION_CHANNEL,
  postMessageAcrossTabs,
  useAcrossTabsListener,
} from "utils/acrossTabsMessaging";
import inIframe, { useInV2Frame } from "utils/in-iframe";
import { useQueryStringValue } from "utils/next";
import useIntercom from "utils/useIntercom";

const CURRENT_USER_PRISMA = gql`
  query CheckLoggedInLogin {
    currentUserPrisma {
      id
    }
  }
`;

function LoginPage() {
  const router = useRouter();
  const returnTo = useQueryStringValue("returnTo");
  const errorCode = useQueryStringValue("error_code");
  const { sendToast } = useToast();
  const { callIntercom } = useIntercom();
  const isWithinV2 = useInV2Frame();

  const { data, refetch } = useQuery<CheckLoggedInLogin>(CURRENT_USER_PRISMA, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    callIntercom("update", { hide_default_launcher: false });
  }, [callIntercom]);

  useAcrossTabsListener({
    channel: LOGIN_COMMUNICATION_CHANNEL,
    listener: () => refetch(),
  });

  useEffect(() => {
    if (data?.currentUserPrisma?.id) {
      // Prevent redirection to an arbitrary external site
      if (returnTo && returnTo.startsWith("/")) {
        router.push(returnTo);
      } else if (!inIframe()) {
        router.push("/");
      }
    }
  }, [data?.currentUserPrisma?.id, returnTo, router]);

  useEffect(() => {
    if (router.isReady && errorCode) {
      let msg = "Something went wrong signing up for Guide";
      switch (errorCode) {
        case ErrorCodes.INACTIVE_USER:
          msg =
            "Your account has been deactivated. Please contact your admin to reactivate your account.";
          break;
        case ErrorCodes.TRIAL_ENDED:
          msg =
            "Your account trial has ended. Please contact sales to reactivate your account.";
          break;
        default:
      }
      sendToast(msg, {
        variant: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady]);

  if (isWithinV2) {
    return (
      <>
        <div className="flex items-center justify-center w-full h-screen">
          <div className="mx-4 max-w-[22.25rem]">
            <div className="mb-6">
              <h1 className="text-h2 mb-4">Sign in to Guide</h1>
              <p className="text-body-lg">
                Please sign in again to use this feature
              </p>
            </div>
            <div className="space-y-3">
              <LoginForm
                onLogin={() => {
                  postMessageAcrossTabs(
                    LOGIN_COMMUNICATION_CHANNEL,
                    new Date().toISOString()
                  );
                  refetch();
                }}
              />
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Head>
        <title>Login</title>
      </Head>
      <div className="h-screen flex flex-col">
        <NotificationBanner />
        <div className="flex flex-grow">
          <div className="flex flex-col w-screen lg:w-1/2">
            <div className="flex flex-col text-center min-[26.25rem]:flex-row justify-between p-4 z-10">
              <Image src={guideLogoDark} alt="Guide Logo" />
              <div className="py-1.5">
                <span className="text-body-md">New to Guide?</span>
                &nbsp;
                <NextLink href="/signup">
                  <DeprecatedLink>Sign up now</DeprecatedLink>
                </NextLink>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center h-full -mt-16">
              <div className="mx-4 max-w-[22.25rem]">
                <div className="mb-6">
                  <h1 className="text-h2 mb-4">Sign in to Guide</h1>
                  <p className="text-body-lg">
                    Welcome to Guide, we&apos;re happy you&apos;re here! To get
                    started, just sign in 👇
                  </p>
                </div>
                <div className="space-y-3">
                  <LoginForm
                    onLogin={() => {
                      postMessageAcrossTabs(
                        LOGIN_COMMUNICATION_CHANNEL,
                        new Date().toISOString()
                      );
                      refetch();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="hidden lg:block lg:w-1/2 bg-light-gray-200">
            <div className="flex flex-col items-center justify-center h-full">
              <div className="">
                <Image
                  src={heroIllustration}
                  layout="fixed"
                  width={528}
                  height={528}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginPage;
